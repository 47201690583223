/* EmotionPlayer.css */

.emotion-player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Full viewport height */
  box-sizing: border-box;
  background-color: var(--focus0);
}

.audio-text{
  display: flex;
  flex-direction: row;
  align-items: stretch; /* Make children take up full height */
  width: 100%;
  /* gap:20px */
}

.audio-section {
  margin: 10px 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 30%;
  background-color: var(--focus1);
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  flex-grow: 1; /* Allow sections to grow and take up available height */
  height: 120px;
}

.current-text-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin: 10px 20px;
  margin-left: 10px;
  /* height: 100%;  */
  overflow-y: auto; 
  background-color: var(--focus1);
  border-radius: 10px;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-grow: 1; /* Allow sections to grow and take up available height */
  height: 120px
}

.audio-text h3{
  margin: 10px 0px;
}

.current-text{
  display: flex;
  flex-direction: row;
}

.visualization-emotions-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 20px;  
  /* width: 100%; */
  width: calc(100% - 40px); 
  border-radius: 10px;
  background-color: var(--focus1);
  /* padding: 0px 10px; */
  /* gap: 20px; */
}

.emotion-title-container{
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin: 10px 0px;
  margin-bottom: 20px;
}

.speaker-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap to the next line if necessary */
  /* margin-bottom: 20px; */
}

/* Individual Legend Items */
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Space between legend items */
  /* margin-bottom: 10px; */
}


.legend-color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  /* border: 1px solid #000; */
}

/* Speaker Name */
.legend-speaker-name {
  font-size: 14px;
  font-weight: bold;
  color: #333; /* Adjust color for better readability */
}


.visualization-emotions-container h3 {
  padding-left: 10px;
  margin: 0px 0px;
  text-align: left;
}


.visualization-emotions-container2{
  display: flex;
  flex-direction: row;
  /* gap:10px */
}



.emotions-list {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  width: 200px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.emotion-item {
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.average-scores-container {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.average-score-item {
  margin-bottom: 8px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

/* Style for the timeline section */
.emotion-timeline-container {
  display: flex; /* Change from column to row */
  align-items: stretch; /* Ensure children stretch to match height */
  position: relative;
  /* margin-top: 20px; */
  padding: 10px 0px;
  /* background-color: #f0f0f0; */
  border-radius: 8px;
  width: 100%;
  cursor: pointer; /* Makes the whole timeline draggable */
}

/* Emotion labels container */
.emotion-labels {
  display: flex;
  flex-direction: column;
  border-right: 1px solid darkgray;  /* 2px wide, solid black border */
}


/* Each row for an emotion */
.emotion-row {
  display: flex;
  align-items: center; /* Vertically center the boxes and labels */
  height: 40px; /* Set a fixed height for each row */
  position: relative;  
}

/* .emotion-row:not(:last-child) {
  border-bottom: 1px solid #ccc; 
} */


.row-separator {
  position: absolute;
  left: 0;
  right: 0;
  height: 0; /* No height since we're using border */
  border-top: 1px dotted #ccc; /* Dotted line; adjust color and thickness as needed */
  z-index: 5; /* Ensure it appears above emotion boxes if needed */
}



/* Emotion label on the left */
.emotion-label {
  flex: 0 0 140px;
  text-align: center;
  /* padding-right: 10px; */
  font-size: 14px;
}

/* Single Horizontal timeline track */
.timeline-track {
  position: relative;
  flex: 1;
  /* background-color: lightgray; */
  height: auto;
  display: flex;
  align-items: center;
}

/* Vertical line inside the timeline */
.timeline-vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  /* width: 2px;
  background-color: darkgreen; */
  width: 0px;
  border-left: 2px dotted darkgreen; /* Create a 2px wide dotted line */
  transition: left 0.1s linear;
  z-index: 10; /* Ensures it appears on top of everything */
}


/* Emotion boxes to represent duration */
.emotion-box {
  position: absolute;
  background-color: green;
  color: white;
  padding:5px 0px;
  border-radius: 4px;
  font-size: 12px;
  height: 20px;
  display: inline-block;
  align-items: center;
}

.plutchik-container{
  padding-right: 10px;
  
  width: 300px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.plutchik-container p{
  padding: 10px;
}


/* Responsive adjustments */
@media (max-width: 768px) {

  /* .emotion-player-container{
    padding-left: 10px;
    padding-right: 10px;
  } */

  .emotion-player-container{
    width: 100%;
  }

  .current-text-container {
    display: none;
  }

  .audio-section{
    margin-left: 10px;
  }
  
  .visualization-emotions-container {
    margin: 10px 0px;
    width: calc(100% - 20px);
    /* width: 100%; */
    /* width: 90%; */
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    /* padding-left: 10px;
    padding-right: 10px; */
  }

  .emotion-title-container{
    margin: 10px 10px;
  }

  .visualization-emotions-container2 {
    /* width: 100%; */
    margin: 10px 10px;
  }

  .emotion-timeline-container {
    display: none;
  }

  .speaker-legend{
    display: none;
  }

  .plutchik-container{
    width: 90%;
    /* max-width: ; */
    /* margin: 10px 0px;
    padding-right: 0px; */
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
  }

  .plutchik-wheel{
    align-items: center;
    text-align: center;
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
  }

}
