/* src/components/FileUpload/FileUpload.css */
@import '../../styles/colors.css';
  

.form-file{
  display: flex;
  flex-direction: column;
}

.file-upload-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 20px;
  gap: 20px;
}



.file-upload-form input[type="file"] {
  display: none; /* Hide the default file input */
  /* margin-bottom: 10px; */
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 10px;
  text-align: center;
  cursor: pointer;
  background-color: var(--blue); /* Use your color variable */
  color: white; /* Use your color variable */
  border: none;
  border-radius: 12px;
  transition: background-color 0.3s;
  font-size: 20px;
  border-radius: 10px;
  width: 150px;

}


.file-upload-form button {
  padding: 10px 10px;
  background-color: var(--blue);
  /* height: 100px; */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
}

.file-upload-form button:hover {
  background-color: #0056b3;
}


.file-upload-form button:disabled {
  opacity: 50%;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

.upload-message {
  margin-top: 10px;
  color: #555;
}
