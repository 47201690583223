.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000; /* Ensure it appears above other elements */
  }
  
  .popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
  }
  
  /* .popup-container.error {
    border: 2px solid red; 
  } */
    
  .popup-title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .popup-message {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .popup-actions {
    display: flex;
    justify-content: center;
    gap: 30px;
    /* font-size: 20px; */
  }
  
  /* .popup-button {
    padding: 8px 12px;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
 */
  /* .popup-actions .popup-button {
    font-size: 20px !important;
  }
   */
  


  

  
  .popup-actions .confirm {
    background-color: var(--navbar); 
    color: white;
    font-size: 20px;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .popup-actions .cancel {
    background-color: white;
    border-color: var(--text) ;
    color: var(--text);
    border: 2px solid var(--navbar); 
    font-size: 20px;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
  }
  