@import '../../styles/colors.css';


.navbar{
    background-color: var(--navbar);
    color: white;
    height: 60px;
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-logo{
    align-items: center;
    line-height: 1; /* Ensures no extra space above/below text */
    text-decoration: none;
    height: 100%;
    padding-left: 20px;
    display: flex;
}

.logo {
    width: 40px; /* Set desired width */
    height: auto; /* Maintain aspect ratio */
    align-self: center;
    align-content: center;
    margin: auto 0;
}

.navbar-logo a{
    align-items: center;
    text-decoration: none;
    line-height: 1; /* Ensures no extra space above/below text */
    line-height:60px;
    font-size: 20px;
    display: flex;
}

p{
    margin: 0px;
    padding: 20px;
    font-size: 20px;
}

.navbar-links{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 20px;
}


.username {
    font-size: 20px;
    padding: 0px 10px;
    line-height:60px;
    /* align-items: center; */
  }

/* .credits{
    font-size: 20px;
    padding: 0px 10px;
    align-items: center;
    line-height:60px;
} */

.logout-button {
    background-color: var(--focus0); /* Red button for logout */
    color: var(--text);
    border: none;
    padding: 5px 10px;
    margin: auto 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    /* height:40px; */
    display: flex;
    align-items: center;
    height: 40px; /* Set explicit height */
  }
  
.logout-button:hover {
background-color: var(--focus1); /* Darker red on hover */
}

.credits-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0px 10px;
    border-radius: 20px;
    background-color: var(--focus0); /* Background color for the circle */
    color: var(--text); /* Text color */
    font-size: 16px; /* Adjust font size */
    font-weight: bold;
    cursor: pointer; /* Indicate interactivity */
    position: relative; 
}

/* Hover effect for credits-circle */
.credits-circle:hover {
    background-color: var(--focus2); /* Slightly darker background on hover */
}

/* Custom tooltip */
.credits-circle:hover::after {
    content: "Remaining credits\A 1 upload minute = 1 credit"; /* Use \A for line break */
    white-space: pre-wrap; /* Ensure line breaks are respected and text wraps */
    position: absolute;
    text-align: center;
    bottom: -60px; /* Position the tooltip below the circle */
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--focus2); /* Tooltip background */
    color: var(--text); /* Tooltip text color */
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    z-index: 10; /* Ensure it's above other elements */
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
    width: 200px;
    font-weight: 500;
    /* height: 50px; */
}

/* Hide tooltip by default */
.credits-circle::after {
    opacity: 0;
    visibility: hidden;
}



/* Small screens (e.g., phones) */
@media (max-width: 768px) {
    .username {
      display: none;
    }

    .navbar-links{
        padding-right: 10px;
    }


}
  