/* src/components/Login/Login.css */

.login-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: var(--focus1);
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    padding: 20px;
    padding-top: 20px;
    margin-top: 50px;
    margin-right: 10%;
    border-radius: 20px;
  }
  
  .login-title {
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    color: var(--blue-dark);
  }
  
  .username-input {
    padding: 10px;
    margin: 10px 10px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(100% - 20px);
    font-size: 20px;
  }

  .form-element{
    display: flex;
    flex-direction: column; /* Stack form elements vertically */
    align-items: center; /* Center elements horizontally */
    justify-content: center; /* Center elements vertically */
    width: 100%;
  }
  
  .login-button {
    padding: 10px 10px;
    margin-top: 20px;
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    width: 100%;
    align-self: center;
    align-content: center;
  }
  
  .login-button:disabled {
    background-color: var(--blue);
    opacity: 60%;
    cursor: not-allowed;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  /* Message Styles */
  .message {
    /* margin-top: 10px; */
    font-size: 18px;
  }
  
  .message.success {
    color: green;
  }
  
  .message.error {
    color: red;
  }
  

  @media (max-width: 768px) {
  
    .login-container {
      width: 85%;
      /* margin-top: 10px; */
      margin: 0 auto;
      padding: 10px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column; /* Ensure elements stack vertically */
      align-items: center;
      justify-content: center;
    } 

    .form-element{
      width: 80%;
      margin: 10px 0; /* Space above and below */
      align-self: center; /* Explicitly center this element */
    }

    .username-input{
      width: calc(100% - 20px);
      margin: 0px;
      padding: 10px 10px;
      align-self: center; /* Explicitly center this element */
    }

    .login-button{
      width: 100%;
      align-self: center; /* Explicitly center this element */
      margin-bottom: 20px;
  }

    .login-title{
      text-align: center;
    }

  
  }
