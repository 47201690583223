/* src/components/UniqueIdDropdown/UniqueIdDropdown.css */

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 20px;
}

.dropdown-container label {
  margin-bottom: 5px;
  font-size: 20px;
  padding-left: 10px;
  /* font-weight: bold; */
}

.dropdown-wrapper {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.dropdown-content.disabled {
  opacity: 0.5;
  pointer-events: none; /* Disable interactions */
}



.dropdown-container select {
  padding: 8px;
  border: 1px solid #ccc;
  min-width: 200px;
  background-color: var(--focus2);
  width: 280px;
  font-size: 18px;
  border-radius: 5px;
}

.rename-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 28px;
  display: inline-flex;
}

.rename-icon img {
  width: 28px;
  height: 28px;
  fill: currentColor; /* This allows the SVG to inherit the color */
}


.rename-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.rename-input {
  padding: 8px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 262.5px;
}

.rename-buttons {
  margin-top: 10px;
}

.rename-buttons button {
  padding: 8px 12px;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
}

.rename-buttons button:first-child {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  width: 135px;
}

.rename-buttons button:last-child {
  background-color: #e94e77;
  color: white;
  border: none;
  border-radius: 5px;
  width: 135px;
}

.dropdown-container.error {
  color: #e94e77;
}

.rename-container .rename-label {
  display: block; /* Makes the label take the full width */
  margin-bottom: 5px; /* Adds some space between the label and the input */
  font-size: 16px; /* Change this value to your desired font size */
  font-style: italic; /* This makes the text italic */
}


/* Spinner Overlay */
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent overlay */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner Animation */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #000; /* Spinner color */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 480px) {
  .dropdown-container {
    width: 250px;
    margin-left:10px;
    margin-right:10px;
  }


  .dropdown-wrapper {
    max-width: 305px;
  }

  .rename-input{
    width: auto;
    max-width: 262px;
  }

  .rename-icon {
    width: 20px;
    /* height: 28px; */
    margin-left: 5px;
  }


  .navbar-links{
      padding-right: 10px;
  }
}
