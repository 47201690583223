.plutchik-wheel {
    max-width: 300px;  /* Ensures the svg scales properly */
    max-height: 300px;  /* Ensures the svg scales properly */
  }
    
  
  .category-label {
    /* font-size: 12px; */
    fill: black;
    pointer-events: none;
  }
  
  .category-slice {
    transition: fill 0.3s ease;
  }
  