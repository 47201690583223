@import '../../styles/colors.css';

.root{
  padding: 0px;
  margin: 0px;
}

.mobile-warning {
  background-color: #ffc107; /* Warning yellow color */
  color: #000; /* Black text for visibility */
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 16px;
}


.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 10px; */
    background-color: var(--focus0);
    min-height: 100vh;
    box-sizing: border-box;
}

.dashboard-container h1 {
    margin-bottom: 30px;
    color: #333;
}


.top-container{
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  margin-top: 10px;
  /* gap:20px; */
  /* padding: 0px 50px;
  margin: 0px 20px; */
}

.file-upload-container {
  width: 30%;
  max-width: 600px;
  background-color: var(--focus1);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 20px;
  margin-right: 10px;
}


.results-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background-color: var(--focus1);
  margin: 10px 20px;
  margin-left: 10px;
  padding: 0px 10px;
}

.results-container h3{
  margin: 10px 0px;
  text-align: center;
}

.plutchik-row{
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */

}

.plutchik-container{
  display: flex;
  flex-direction: column;
}

  
/* Notification Container */
.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

/* Notification Styles */
.notification {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  margin-bottom: 10px;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

/* Success Notification */
.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Error Notification */
.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Fade In and Out Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it covers everything */
  display: flex;
  flex-direction: column;
}

.spinner {
  border: 16px solid #f3f3f3; /* Light gray */
  border-top: 16px solid var(--blue); /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.progress-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 20px;
}

.progress-container p {
  font-size: 28px;
  font-weight: 600;
  color: white;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px auto;
}

.progress-bar-inner {
  height: 100%;
  background-color: #4caf50; /* Green */
  transition: width 0.5s ease;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding-top:80px;
}

/* ///////////// Small screens /////////////////////////// */
/* Small screens (e.g., phones) */
@media (max-width: 768px) {

  .top-container {
    width: 100%;
    margin-top: 30px;
  }

  .results-container {
    display: none;
    width: 0px;
  }

  .file-upload-container {
    width: 100%;
    margin-left: 10px;
  }

  .dropdown-container {
    width: auto;
  }

}

