.landing-container {
    height: calc(100vh - 60px); 
    /* height: 100vh;  */
    overflow: hidden; /* Ensure no overflow */
    background-image: url('../../assets/landing_pic7.png'); /* Set background image */
    background-size: cover; /* Cover the entire container */
    background-position: center; 
    display: flex; /* Use Flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    /* justify-content: center;  */
    /* align-items: ce
    nter; */
}

.element-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 25vh;
}   

.content-container {
    width: 70%;
    padding-left: 10%;
}

.main-title {
    font-size: 54px; /* Adjust font size as needed */
    margin-bottom: 20px; /* Space between title and paragraphs */
    color: var(--blue-dark);
}

.content-container p {
    font-size: 24px;
    padding: 16px;
}


.popup-error {
    position: fixed;
    top: 50px;
    right: 20px;
    padding: 10px 20px;
    background-color: rgba(255, 0, 0, 0.85); /* Semi-transparent red */
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    z-index: 1000;
    animation: fadeInOut 5s ease forwards;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }

@media (max-width: 768px) {

    .landing-container {
        height: auto;
    } 
    

    .element-container {
        /* margin-top: 10vh; */
        display: flex;
        flex-direction: column;
        margin-top: 30px;

    }

    .content-container{
        padding: 20px;
        margin: 0px auto;
        width: 90%;
    }
}
  
  